.loginBox {
  height: 100vh;
}

.registerBox {
  height: 100vh;
}

.loginBox .loginContainer {
  max-width: 390px;
}

.registerBox .registerContainer {
  max-width: 550px;
}

.error-title {
  font-size: 210px;
  font-weight: 900;
  text-shadow: 4px 4px 0 #fff, 6px 6px 0 #263238;
  line-height: 210px;
}

.rojito {
  color: red;
  font-size: 18px;
}

.azulito {
  color: blue;
  font-size: 18px;
}



.boxContainer {
  max-width: 1400px;
}

element.style {
  position: absolute;
}

.fotoSeleccionar {
  width: 220px;
  height: auto;
  align-content: center;
}